<template>
  <div
      class="bg-red-50 bg-opacity-80 my-2 p-4 rounded-lg border border-red-500 shadow-md hover:bg-red-100 transition-all duration-300 ease-in-out">
    <p class="text-red-700 font-semibold">{{ title }}</p>
    <p class="text-red-600">{{ message }}</p>
  </div>
</template>

<script>

export default {
  name: 'Callout',
  props: {
    title: {
      type: String,
      default: 'Warning',
    },
    message: {
      type: String,
      default: 'This is a warning message.',
    },
  },
}
</script>
<style>

</style>
